import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import { Button, DialogActions, IconButton, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const CollectionDeleteDialog = ({open,onClose,onDelete,isSubmitting}) => {
    return (
        <div>
        <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: 'm-24',
        }}
        fullWidth
        maxWidth="sm"
      >
        <Toolbar className="flex w-full justify-between">
          <Typography
            variant="h6"
            color="inherit"
            className="font-semibold text-18 sm:text-24"
          >
            Confirmation
          </Typography>

          <IconButton color="secondary"></IconButton>
        </Toolbar>

        <DialogContent classes={{ root: 'p-24' }}>
          <div className="font-bold">
            Are you sure to delete this record?
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnClose"
            className="btn btn-primary btn-sm"
            onClick={onClose}
           loading={isSubmitting}
          >
            No
          </Button>
          <LoadingButton
            id="btnDelete"
            className="btn btn-dark btn-sm ms-5"
            variant="contained"
            color="secondary"
            onClick={()=>onDelete()}
           loading={isSubmitting}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
        </div>
    )
}
export default CollectionDeleteDialog