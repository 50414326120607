import React from "react";
import SearchField from "./component/SearchField";
import SearchList from "./list/SearchList";
import { Divider } from "@mui/material";

function SearchWrapper() {
  return (
    <div className="pl-8">
      <div className="flex-col lg:w-3/5 sm:w-full md:w-full pb-5">
        <SearchField />
      </div>
      <Divider className="pt-1 w-full" />
      <span className="text-gray-500 text-xs">
        About 7,940,000,000 results (0.57 seconds)
      </span>
      <div className="flex-col lg:w-3/5 sm:w-full md:w-full">
        <SearchList />
      </div>
    </div>
  );
}
export default SearchWrapper;
