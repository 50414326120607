import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { IconButton, ListItemIcon } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import clsx from 'clsx'

const CollectionListItem = ({ collection, onDeleteOpen, onEdit }) => {
  return (
    <>
      <ListItem alignItems="flex-start"
      >
        <ListItemText
          primary={`${collection.displayName} (${collection.name})`}
          secondary={collection.is_default ? 'Default' : ''}
        />
        <ListItemIcon>
          <IconButton
            aria-label="delete"
            id="btn-delete"
            onClick={() => onEdit(collection)}
            disabled={collection.is_default}
          >
            <EditIcon className={clsx(collection.is_default ? 'invisible' : '')} />
          </IconButton>

        </ListItemIcon>

        <ListItemIcon>
          <IconButton

            aria-label="delete"
            id="btn-delete"
            onClick={() => onDeleteOpen(collection)}
            className={clsx(collection.is_default ? 'invisible' : '')}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemIcon>
      </ListItem>
      <Divider component="li" className="my-5" />
    </>
  );
};

export default CollectionListItem;
