import { useState, useEffect, React } from "react";
import SearchListItem from "./SearchListItem";

import searchResultServices from "../../../services/searchService/searchService";
const queryString = require("query-string");

function SearchList() {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const queryParam = queryString.parse(window.location.search);
      const results = await searchResultServices.fetchSearchResult(queryParam);
      setSearchResults(results);
    };
    fetch();
  }, []);

  const handleGoodClick = async () => {
    const params = queryString.parse(window.location.search);
    await searchResultServices.goodClick(params);
  };
  const handleBadClick = async () => {
    const params = queryString.parse(window.location.search);
    await searchResultServices.badClick(params);
  };

  return (
    <div>
      {searchResults.map((result, index) => (
        <SearchListItem
        className="pt-4"
          key={index}
          result={result}
          goodClick={handleGoodClick}
          badClick={handleBadClick}
          index={index}
        />
      ))}
    </div>
  );
}
export default SearchList;
