import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Groups = ({ onChange, groups, label }) => {
  return (
    <FormControl
      sx={{
        minWidth: 220,
      }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select id="select" name="group" label="Select group" onChange={onChange}>
        <MenuItem value="">Select</MenuItem>
        {groups?.map((group) => (
          <MenuItem value={group._key} key={group._key}>
            {group.groupName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Groups;
