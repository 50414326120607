import axios from "axios";
import { baseApiUrl } from "../../config";

class searchResultService {
  fetchSearchResult = async (params) => {
    const response = await axios.get(`${baseApiUrl}/`, {
      params,
    });
    return response.data;
  };

  goodClick = async (params) => {
    await axios.post(`${baseApiUrl}/good/click`, params);
  };

  badClick = async (params) => {
    await axios.post(`${baseApiUrl}/bad/click`, params);
  };
}

const instance = new searchResultService();

export default instance;
