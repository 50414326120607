import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { utcToLocal } from "../../helpers/date-formatter";
import { statusList } from "../../utilities/statusList";
import clsx from "clsx";
import { capitalize } from "lodash";
import { find } from "lodash";

function UploadKeywordsListItem({ files }) {
    const getStatus = (status) => {
        const statusItem = find(statusList, { id: capitalize(status) });
        return statusItem ? statusItem.color : "bg-blue text-white";
    };

    return (
        <TableRow>
            <TableCell className="p-4 md:p-16" component="th" scope="row">
                {utcToLocal(files.date)}
            </TableCell>
            <TableCell
                className="p-4 md:p-16"
                component="th"
                scope="row"
            >
                {files.collection}
            </TableCell>
            <TableCell
              
                className="p-4 md:p-16"
                component="th"
                scope="row"
            >
                {files.file_name}
            </TableCell>
            <TableCell
               
                className={clsx(
                    getStatus(files.status),
                    "inline text-11 font-500 px-8 py-4 rounded-4"
                )}
                component="th"
                scope="row"
            >
                {files.status}
            </TableCell>
            <TableCell
               
                className="p-4 md:p-16 bg-green"
                component="th"
                scope="row"
            >
                Uploaded : {files.created_rows}
            </TableCell>

        </TableRow>
    )
}
export default UploadKeywordsListItem