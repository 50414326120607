import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import {
    TableBody,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    Paper,
    TextField,
    Snackbar,
    Alert,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import reject from 'lodash/reject';
import settingService from '../../services/settingService';
import importKeywordService from '../../services/importKeywordService'
import UploadKeywordsListItem from './UploadKeywordsListItem';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from '@mui/lab';
import { extensionCheck } from '../../utilities';
import Papa from 'papaparse';

const initialFilter = {
    collectionName: 'Default',
};

function UploadKeywords() {
    const [collections, setCollections] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [collection, setCollection] = useState(initialFilter);
    const [toastOpen, setToastOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");



    useEffect(() => {
        const fetch = async () => {
            const collections = await settingService.listCollection();
            setCollections(collections);
            const fetchUploadKeywords = await importKeywordService.fetchFile(collection.collectionName)
            setUploadFiles(fetchUploadKeywords)
        };
        fetch();
    }, [collection]);

    const fetchUploadCollections = async () => {
        const fetchUploadKeywords = await importKeywordService.fetchFile(collection.collectionName)
        setUploadFiles(fetchUploadKeywords)
    }

    const handleCollectionChange = (e) => {
        const { value: collectionName } = e.target;
        setCollection({ ...collection, collectionName });
    };

    const handleFileSubmit = async () => {
        setIsSubmitting(true)
        try {
            if (isFileSelected) {
                setErrorMessage('')
                const formData = new FormData();
                formData.append('file', file[0]);
                formData.append('collectionName', collection.collectionName)
                const FileCollectionKey = await importKeywordService.uploadFile(formData)
                const fetchUploadKeywords = await importKeywordService.fetchFile(collection.collectionName)
                setUploadFiles(fetchUploadKeywords)
                importKeywords(FileCollectionKey)
                successToastMessage()
                setFileName("")
                setIsFileSelected(false)
            } else {
                setErrorMessage('Please select a file')
            }
        } catch (error) {
            errorToastMessage()
            console.error(error);
        }
        finally {
            setIsSubmitting(false)
        }
    }

    const handleFileChange = (event) => {
        setFileName(event.target.value)
        const valid = extensionCheck(event.target.value);
        if (valid) {
            setErrorMessage("");
            setFile(event.target.files)
            parseData(event.target.files[0])
            setIsFileSelected(true)
        }
        else {
            setErrorMessage('Invalid file type');
            setIsFileSelected(false)
        }
    }

    const parseData = (file) => {
        Papa.parse(file, {
            skipEmptyLines: true, header: true, step: (results) => {
                const isHitsAvailable = results.meta.fields.includes('Hits')
                const isMatchedHitsAvailable = results.meta.fields.includes('Matched Hits')
                const isKeywordAvailable = results.meta.fields.includes('Keyword')
                if(!isHitsAvailable || !isMatchedHitsAvailable || !isKeywordAvailable){
                    setErrorMessage('The data must have specified format');
                    setIsFileSelected(false)
                }
            }
        });
    }

    const importKeywords = async (FileCollectionKey) => {
        await importKeywordService.importFile(FileCollectionKey[0])
    }

    const successToastMessage = () => {
        setToastOpen(true);
        setMessage(() => 'Uploaded successfully');
        setSeverity(() => 'success');
    }
    const handleToastClose = () => {
        setToastOpen(false);
    };

    const errorToastMessage = () => {
        setToastOpen(true);
        setMessage(() => 'Error occurred while Uploading');
        setSeverity(() => 'error');
    }

    return (
        <>
            <Paper className="w-full rounded-20 px-16 py-5  shadow leading-normal ">
                <div className="flex justify-between w-full">
                    <h1 className="text-xl pb-10 pt-16">Upload Keywords </h1>

                    <div className='pt-16 pb-10'>
                        <a className="underline text-blue-400" href="http://traffic-reader.s3.us-west-1.amazonaws.com/template/Traffic+reader+keyword.csv">
                            Download template
                        </a>
                    </div>
                </div>
                <div className="flex justify-start w-full grid grid-cols-3">
                    <label className="text-l">Select collection to upload</label>
                    <div className="mr-24">
                        <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small">Collection</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={collection.collectionName}
                                label="Collection"
                                defaultValue=""
                                onChange={handleCollectionChange}
                            >
                                <MenuItem value="Default">Default</MenuItem>
                                {reject(collections, { is_default: true }).map(
                                    (collection) => (
                                        <MenuItem value={collection.name} key={collection.name}>
                                            {collection.displayName}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="flex justify-start pt-10 grid grid-cols-3">
                    <label className="text-l">Select file to upload</label>
                    <div className="pl-12 mr-50 ml-0 pl-0">
                        <TextField
                            id='file'
                            accept=".csv"
                            type="file"
                            multiple={false}
                            onChange={handleFileChange}
                            size="small"
                            value={fileName}
                        />
                        {errorMessage && <div >
                            <div className='text-start text-red-500 ml-50 pt-3'>{errorMessage}</div>
                        </div>}
                    </div>
                    <div className="mr-24 pl-12">
                        <LoadingButton id="upload" type='submit' variant="contained" onClick={handleFileSubmit} disabled={!isFileSelected} loading={isSubmitting}>
                            Upload
                        </LoadingButton>
                    </div>

                </div>

                <div className="flex justify-between pt-5">
                    <h1 className="text-xl pb-6 pt-16">Upload history</h1>
                    <IconButton onClick={() => fetchUploadCollections()}>

                        <AutoRenewIcon />
                    </IconButton>
                </div>
                <Divider className="py-2" />
                <div className="w-full flex flex-col min-h-full md:w-full table-responsive overflow-x-auto relative">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="text-lg font-semibold">
                                    <Typography
                                        color="textSecondary"
                                        className="text-lg font-semibold"
                                    >
                                        Date
                                    </Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography color="textSecondary" className="font-semibold">
                                        Collection
                                    </Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography color="textSecondary" className="font-semibold">
                                        File name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary" className="font-semibold">
                                        Status
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography color="textSecondary" className="font-semibold">
                                        Summery
                                    </Typography>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uploadFiles && uploadFiles?.map((files, index) => (
                                <UploadKeywordsListItem
                                    files={files}
                                    key={index}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>

            </Paper>
            <Snackbar
                open={toastOpen}
                autoHideDuration={5000}
                onClose={handleToastClose}
                message={message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert variant="filled" severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}
export default UploadKeywords