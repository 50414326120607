import React, { useEffect, useState } from "react"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import reject from 'lodash/reject';
import settingService from "../../services/settingService";
import { Button } from "@mui/material";

const SettingCollectionSelect = ({ onSelect, selectedValue, handleClick, names }) => {
  return (
    <div>
      <span className="mr-24" >
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
          <InputLabel id="demo-select-small">RedirectURL Setting</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={selectedValue}
            label="name"
            onChange={onSelect}
          >
            {reject(names, { is_default: true }).map(
              (setting) => (
                <MenuItem value={setting.name} key={setting.name}>
                  {setting.displayName}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </span>
      <span className="pr-5">
        <Button id="btnAdd" variant="contained" className="btn btn-lg ms-5" onClick={handleClick}>Add New Redirect URL Setting</Button>
      </span>
    </div>
  )
}
export default SettingCollectionSelect