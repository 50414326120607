import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SettingForm from './SettingForm';
import CollectionSettings from './CollectionSettings';
import { Paper } from '@mui/material';

const Settings = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper className="w-full overflow-x-auto">
    <Tabs value={currentTab} onChange={handleTabChange} >
      <Tab label="Redirect Url Settings" />
      <Tab label="Keyword Collection Settings" />
    </Tabs>

    {currentTab === 0 && <SettingForm />}
    {currentTab === 1 && <CollectionSettings />}
  </Paper>
  );
};

export default Settings;
