import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import filter from 'lodash/filter';
import settingService from '../../services/settingService';
import CollectionList from './CollectionList';
import AddCollectionDialog from './AddCollection';
import CollectionDeleteDialog from './CollectionDeleteDialog';
import { Button } from '@mui/material';
import { snakeCase } from 'lodash';

const initialState = {
  open: false,
  openDialog: false,
  message: '',
  selectedKey: '',
  name: '',
  isSubmitting: false,
}

function CollectionSettings() {
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState({
    name: '',
    displayName: ''
  });
  const [state, setState] = useState(initialState)
  const [severity, setSeverity] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const collections = await settingService.listCollection();
      setCollections(collections);
    };
    fetch();
  }, []);

  const handleSave = async () => {
    const collections = await settingService.listCollection();
    setCollections(collections);
  };

  const checkIsCollectionNameExist = (name) => {
    const results = filter(collections, { name });
    return results.length > 0;
  };

  const handleToastClose = () => {
    setState(initialState)
  };

  const handleDeleteOpen = (collection) => {
    setState((state) => ({
      ...state,
      open: true,
      selectedKey: collection.key,
      name: collection.name
    }));
  };

  const handleDeleteClose = () => {
    setState(initialState)
  };

  const handleDeleteCollection = async () => {
    try {
      await settingService.deleteSettingCollection({ key: state.selectedKey, name: state.name })
      const collections = await settingService.listCollection();
      setCollections(collections);
      setSeverity(() => 'success');
      setState({ ...state, isSubmitting: true, open: false, openDialog: false, message: 'Deleted successfully' });
    } catch (err) {
      setSeverity(() => 'error');
      setState({ ...state, isSubmitting: true, open: false, openDialog: false, message: 'Error occurred while deleting' });
      console.error(err);
    }

  };

  const handleClickOpen = () => {
    setSelectedCollections('');
    setState((state) => ({
      ...state,
      openDialog: true,
    }));
  };

  const handleEdit = async (data) => {
    setSelectedCollections(data);
    setState((state) => ({
      ...state,
      openDialog: true,
    }));
  }

  const handleClose = () => {
    setState(initialState);
  };


  const create = async (model) => {
    if (model.name.length) {
      try {
        if (checkIsCollectionNameExist(model.name)) {
          setSeverity(() => 'error');
          setState({ ...state, openToast: true, open: false, openDialog: false, isSubmitting: true, message: 'Collection already exists' });
        }
        const response = await settingService.createCollection({
          name: model.name,
          displayName: model.displayName,
          isDefault: false,
        });
        if (response) {
          setSeverity(() => 'error');
          setState({ ...state, isSubmitting: true, open: false, openDialog: false, message: 'Collection already exists' });
        } else {
          setSeverity(() => 'success');
          setState({ ...state, isSubmitting: true, open: false, openDialog: false, message: 'Collection created successfully. Please set redirect URLs', });
        }
      } catch (error) {
        setState({ ...state, openToast: true, isSubmitting: true, message: 'Error occurred while creating', severity: 'error', });
        console.error(error);
      }
    }
  };

  const update = async (model) => {
    try {
      await settingService.createCollection(model);
      setSeverity(() => 'success');
      setState({ ...state, open: false, openDialog: false, isSubmitting: true, message: 'Collection name updated successfully' });
    } catch (error) {
      setSeverity(() => 'error');
      setState({ ...state, open: false, openDialog: false, isSubmitting: true, message: 'Error occurred while updating', });
      console.error(error);
    }
  };

  async function onSubmit(model) {
    let collection = { ...model }
    collection.name = snakeCase(model.name)
    if (selectedCollections.key) {
      await update(collection)
    } else {
      await create(collection);
    }
    handleSave()
  }

  return (
    <>
      <CardHeader
        action={
          <Button variant="contained" onClick={handleClickOpen}>
            Add keyword Collection
          </Button>
        }
        title="Keyword collections"
      />
      <CardContent className="flex flex-col w-full">
        <CollectionList collections={collections} onDeleteOpen={handleDeleteOpen} onEdit={handleEdit} />
      </CardContent>

      <Snackbar
        open={state.message.length > 0}
        autoHideDuration={5000}
        onClose={handleToastClose}
        message={state.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" severity={severity}>
          {state.message}
        </Alert>
      </Snackbar>
      {state.open && (
        <CollectionDeleteDialog
          open={state.open}
          onClose={handleDeleteClose}
          onDelete={handleDeleteCollection}
          isSubmitting={state.isSubmitting} />
      )}
      {state.openDialog && (
        <AddCollectionDialog
          checkIsCollectionNameExist={checkIsCollectionNameExist}
          onSubmit={onSubmit}
          openDialog={state.openDialog}
          selectedCollections={selectedCollections}
          isSubmitting={state.isSubmitting}
          onAddDialogClose={handleClose}
        />
      )}
    </>
  );
}
export default CollectionSettings;
