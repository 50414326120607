import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/ClearSharp";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function GroupsListItem({ groups, onDelete, onEdit, onDeleteWithSubId }) {
  return (
    <TableRow>
      <TableCell className="p-4 md:p-16" component="th" scope="row">
        {groups.groupName}
      </TableCell>

      <TableCell className="p-4 md:p-16 " component="th" scope="row">
        <IconButton
          aria-label="delete"
          id="btn-delete"
          onClick={() => onEdit(groups)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell className="p-4 md:p-16 " component="th" scope="row">
        <IconButton
          aria-label="delete"
          id="btn-delete"
          onClick={() => onDeleteWithSubId(groups._key)}
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
      <TableCell className="p-4 md:p-16 " component="th" scope="row">
        <IconButton
          aria-label="delete"
          id="btn-delete"
          onClick={() => onDelete(groups._key)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default GroupsListItem;
