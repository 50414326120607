import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { simpleFloat, utcToLocal } from "../../helpers/date-formatter";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";

function TrafficLogsListItem({ trafficLogs, groupBySubId, onChange }) {
  return (
    <TableRow>
      {groupBySubId && (
        <TableCell className="p-4 md:p-16" component="th" scope="row">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  edge="end"
                  value={trafficLogs.subid}
                  onChange={onChange}
                />
              }
            />
          </FormGroup>
        </TableCell>
      )}

      {!groupBySubId && (
        <TableCell className="p-4 md:p-16" component="th" scope="row">
          {utcToLocal(trafficLogs.date)}
        </TableCell>
      )}

      <TableCell
        className="p-4 md:p-16 max-w-sm break-words"
        component="th"
        scope="row"
      >
        {trafficLogs.subid}
      </TableCell>
      <TableCell
        className="p-4 md:p-16 max-w-sm break-words"
        component="th"
        scope="row"
      >
        {arrOfValues(trafficLogs.groups)}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {trafficLogs.goodClicks}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {trafficLogs.badClicks}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {trafficLogs.totalTraffic}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {simpleFloat(trafficLogs.goodClickCtr)} %
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {simpleFloat(trafficLogs.badClickCtr)} %
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {simpleFloat(trafficLogs.totalCtr)} %
      </TableCell>
    </TableRow>
  );
}

export default TrafficLogsListItem;

export const arrOfValues = (value) => {
  if (value instanceof Array) {
    return `${value}`;
  }
  return value;
};
