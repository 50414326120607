import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

function KeywordLogsListItem({ log, onDeletePopUp }) {
  return (
    <TableRow>
      <TableCell className="p-4 md:p-16" component="th" scope="row">
        {log.keyword}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {log.hits}
      </TableCell>
      <TableCell
        align="right"
        className="p-4 md:p-16"
        component="th"
        scope="row"
      >
        {log.matchedHits}
      </TableCell>
      <TableCell className="p-4 md:p-16 " component="th" scope="row">
        <IconButton
          aria-label="delete"
          id="btn-delete"
          onClick={() => onDeletePopUp(log.keyword)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default KeywordLogsListItem;
