import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CardActions,
  CardContent,
  TextField,
  Typography,
  Stack,
  CardHeader,
  Card,
} from '@mui/material';
import settingService from '../../services/settingService';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SettingCollectionSelect from './SettingCollectionSelect';
import find from 'lodash/find'
import DeleteConfirmationDialog from '../deleteConfirmation/DeleteConfirmation';

const settingSchema = Yup.object().shape({
  name: Yup.string().required('Field is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  display_name: Yup.string().required('Field is required'),
  keyword_save: Yup.string().required('Field is required'),
  keyword_matched_hits_lt_hits: Yup.string().required('Field is required'),
  keyword_matched_hits_ge_hits: Yup.string().required('Field is required'),
  no_keyword_matched_hits_lt_hits: Yup.string().required('Field is required'),
  no_keyword_matched_hits_ge_hits: Yup.string().required('Field is required'),
});

const initialValues = {
  name: '',
  display_name: '',
  keyword_save: '',
  isDefault: false,
  keyword_matched_hits_lt_hits: '',
  keyword_matched_hits_ge_hits: '',
  no_keyword_matched_hits_lt_hits: '',
  no_keyword_matched_hits_ge_hits: '',
};

function SettingForm() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState(false);
  const [settingKeywords, setSettingKeyword] = useState(initialValues);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [namesList, setNameslist] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(settingSchema),
  });


  const setFieldValue = (result) => {
    return (
      setValue("name", result.name),
      setValue("display_name", result.display_name),
      setValue("keyword_save", result.keyword_save),
      setValue("keyword_matched_hits_lt_hits", result.keyword_matched_hits_lt_hits),
      setValue("keyword_matched_hits_ge_hits", result.keyword_matched_hits_ge_hits),
      setValue("no_keyword_matched_hits_lt_hits", result.no_keyword_matched_hits_lt_hits),
      setValue("no_keyword_matched_hits_ge_hits", result.no_keyword_matched_hits_ge_hits),
      setValue("isDefault", result.is_default)
    )
  }
  useEffect(() => {
    const fetch = async () => {
      const nameList = await settingService.listNames();
      if (nameList.length) {
        const keyword = { key: nameList[0].key, name: nameList[0].name }
        const result = await settingService.fetchKeywords(keyword.key);
        setFieldValue(result)
        setSettingKeyword({ ...result, key: keyword.key, isDefault: result.is_default });
        setNameslist(nameList)
      }
    };
    fetch();
  }, [isSubmitting, isDeleteSubmitting]);

  async function onSubmit(model) {
    try {
      setIsSubmitting(true);
      if (!settingKeywords.name) {
        await settingService.create({ ...model });
      }
      else {
        await settingService.create({ ...model, key: settingKeywords.key });
      }
      setOpen(true);
      setMessage(() => 'Saved successfully');
      setSeverity(() => 'success');
    } catch (error) {
      setOpen(true);
      setMessage(() => 'Error occurred while saving');
      setSeverity(() => 'error');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleToastClose = () => {
    setOpen(false);
  };

  const handleCollectionChange = async (e) => {
    const { value } = e.target;
    const data = find(namesList, { name: value })
    // setSettingKeyword({ name: value, key: data.key });
    const result = await settingService.fetchKeywords(data.key)
    setFieldValue(result)
    setSettingKeyword({ ...result, isDefault: result.is_default, name: value, key: data.key });
  };

  const handleAddName = () => {
    reset();
    setSettingKeyword(initialValues);
  }

  const handleDeleteOpen = () => {
    setOpenDeleteDialog(true)
  }


  const handleDeleteClose = () => {
    setOpenDeleteDialog(false)
  }

  const handleDelete = async () => {
    try {
      setIsDeleteSubmitting(true)
      const model = { key: settingKeywords._key }
      await settingService.deleteRedirectUrlSetting(model)
      setOpen(true);
      setMessage(() => 'Deleted successfully');
      setSeverity(() => 'success');
    } catch (error) {
      setOpen(true);
      setMessage(() => 'Error occurred while deleting');
      setSeverity(() => 'error');
    } finally {
      setIsDeleteSubmitting(false)
      handleDeleteClose()
    }
  }

  return (
    <>
      <Card className="w-full">
        <CardHeader
          title="Redirect URL Settings"
          action={
            <SettingCollectionSelect
              onSelect={handleCollectionChange}
              selectedValue={settingKeywords.name}
              handleClick={handleAddName}
              names={namesList} />
          }
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden w-full"
        >
          <CardContent className="flex flex-col w-full">

            <Stack spacing={2} direction="column">
              <Typography>URL Param name</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.name}
                    helperText={errors?.name?.message}

                  />
                )}
              />
              <Typography>Display name</Typography>
              <Controller
                name="display_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.display_name}
                    helperText={errors?.display_name?.message}
                  />
                )}
              />
              <Typography>Redirect URL for save_kw</Typography>
              <Controller
                name="keyword_save"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.keyword_save}
                    helperText={errors?.keyword_save?.message}
                  />
                )}
              />
              <Typography>
                WITH KEYWORD URL for get_kw, if matchedHits {'<'} hits
              </Typography>
              <Controller
                name="keyword_matched_hits_lt_hits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.keyword_matched_hits_lt_hits}
                    helperText={errors?.keyword_matched_hits_lt_hits?.message}
                  />
                )}
              />
              <Typography>
                WITH KEYWORD URL for get_kw, if matchedHits {'>'}= hits
              </Typography>
              <Controller
                name="keyword_matched_hits_ge_hits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.keyword_matched_hits_ge_hits}
                    helperText={errors?.keyword_matched_hits_ge_hits?.message}
                  />
                )}
              />

              <Typography>
                WITHOUT KEYWORD URL for get_kw, if matchedHits {'<'} hits
              </Typography>
              <Controller
                name="no_keyword_matched_hits_lt_hits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.no_keyword_matched_hits_lt_hits}
                    helperText={errors?.no_keyword_matched_hits_lt_hits?.message}
                  />
                )}
              />
              <Typography>
                WITHOUT KEYWORD URL for get_kw, if matchedHits {'>='} hits
              </Typography>
              <Controller
                name="no_keyword_matched_hits_ge_hits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="txt"
                    className="mb-24 w-full"
                    type="text"
                    variant="outlined"
                    error={errors?.no_keyword_matched_hits_ge_hits}
                    helperText={errors?.no_keyword_matched_hits_ge_hits?.message}
                  />
                )}
              />
            </Stack>
          </CardContent>
          <CardActions className="flex justify-end pb-32 pr-32">
            <LoadingButton
              variant="contained"
              color="error"
              loading={isDeleteSubmitting}
              disabled={settingKeywords.isDefault}
              onClick={handleDeleteOpen}

            >
              Delete
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </CardActions>
        </form>
      </Card>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleToastClose}
        message={message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {openDeleteDialog && <DeleteConfirmationDialog
        open={handleDeleteOpen}
        onClose={handleDeleteClose}
        onDelete={handleDelete}
        isSubmitting={isDeleteSubmitting}
      />}
    </>
  );
}
export default SettingForm;
