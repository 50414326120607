import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const collectionSchema = Yup.object().shape({
  name: Yup.string().required('Field is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  displayName: Yup.string().required('Field is required'),
});
const initialState = {
  name: '',
  displayName:'',
};

export default function AddCollectionDialog({
  openDialog,
  onAddDialogClose,
  selectedCollections,
  onSubmit,
  isSubmitting
}) {

  const { control, handleSubmit,formState: { errors }} = useForm({
    mode: "onChange",
    defaultValues: selectedCollections || initialState,
    resolver: yupResolver(collectionSchema),
    shouldFocusError: true,
  });

  return (
    <div>
      <Dialog open={openDialog} onClose={onAddDialogClose} fullWidth>
        <DialogTitle>Create New Collection</DialogTitle>
        <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent>
          <Controller
            name="displayName"
            control={control}
            render={({ field }) => (
          <TextField
          {...field}
            autoFocus
            margin="dense"
            id="displayName"
            label="Collection Name"
            fullWidth
            variant="outlined"
            type="text"
            error={errors?.displayName}
            helperText={errors?.displayName?.message}
          />
          )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            id="name"
            label="URL Param name"
            fullWidth
            variant="outlined"
            type="text"
            error={errors?.name}
            helperText={errors?.name?.message}
            disabled={selectedCollections && selectedCollections.name.length>0}
          />
          )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddDialogClose} variant="outlined" color="secondary" loading={isSubmitting}>
            Cancel
          </Button>
          <LoadingButton
            loadingPosition="start"
            variant="outlined"
            type="submit"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
