import axios from "axios";
import { baseApiUrl } from "../../config";

class trafficLogsServices {
  fetchLogs = async (filter) => {
    const response = await axios.post(`${baseApiUrl}/traffics`, filter);
    return response.data;
  };
}

const instance = new trafficLogsServices();

export default instance;
