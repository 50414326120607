import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import SearchWrapper from "./features/SearchList/SearchWrapper";
import TrafficLogsList from "./features/trafficLogs/TrafficLogsList";
import KeywordLogsList from "./features/keywordLogs/KeywordLogsList";
import Setting from "./features/setting";
import GroupsList from "./features/groups/GroupsList";
import UploadKeywords from "./features/uploadKeywords/UploadKeywords";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<SearchWrapper />} />
          <Route path="/logs" element={<TrafficLogsList />} />
          <Route path="/traffics" element={<TrafficLogsList />} />
          <Route path="/keywords" element={<KeywordLogsList />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/manage-groups" element={<GroupsList />} />
          <Route path="/upload-keywords" element={<UploadKeywords />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
