import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Typography,
  Button,
  DialogActions,
  Toolbar,
  TextField,
} from "@mui/material";
import groupService from "../../services/groupService";

const schema = yup.object().shape({
  groupName: yup.string().required("Please enter a group name"),
});

const initialvalues = {
  groupName: "",
};

function Group({ open, onClose, selectedData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: selectedData || initialvalues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const onSubmit = async (values) => {
    try {
      await groupService.create(values);
      setIsSubmitting(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: "m-24",
        }}
        fullWidth
        maxWidth="sm"
      >
        <Toolbar className="flex w-full justify-between">
          <Typography
            variant="h6"
            color="inherit"
            className="font-semibold text-18 sm:text-24"
          >
            Add group
          </Typography>
        </Toolbar>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent classes={{ root: "p-24" }}>
            <Controller
              name="groupName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="txtGroupName"
                  className="mb-24 w-full"
                  label="Group Name"
                  type="text"
                  variant="outlined"
                  error={!!errors?.groupName}
                  helperText={errors?.groupName?.message}
                  required
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              id="btnClose"
              className="btn btn-primary btn-sm"
              loading={isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              id="btnDelete"
              className="btn btn-dark btn-sm ms-5"
              variant="contained"
              color="secondary"
              type="submit"
              loading={isSubmitting}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
export default Group;
