import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TableBody, Divider, TextField, Paper } from "@mui/material";
import TrafficLogsListItem from "./TrafficLogsListItem";
import trafficLogsServices from "../../services/trafficLogsServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import LogTableHeader from "./LogTableHeader";
import { getStartOfDayUTCTimestamp } from "../../helpers/date-formatter";
import orderBy from "lodash/orderBy";
import groupService from "../../services/groupService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Groups from "./Groups";

const pageSize = 20;
const initialFilter = {
  date: moment().subtract(7, "days").format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  offset: 0,
  searchText: "",
  groupBySubId: false,
};

const initialValues = {
  subId: [],
};

function TrafficLogsList() {
  const [trafficLogs, setTrafficLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState(initialFilter);
  const [order, setOrder] = useState({
    direction: "asc",
    id: "date",
  });
  const [groups, setGroups] = useState([]);
  const [state, setState] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const result = await trafficLogsServices.fetchLogs({
        ...initialFilter,
        date: getStartOfDayUTCTimestamp(
          moment().subtract(7, "days").format("YYYY-MM-DD")
        ),
        toDate: getStartOfDayUTCTimestamp(),
      });
      setTrafficLogs(result.data);
      const totalPages = parseInt(result.totalRecords / pageSize);
      setTotalPages(totalPages);
      const groups = await groupService.fetchGroups();
      setGroups(groups);
    };
    fetch();
  }, []);

  const handlePageChange = async (e, value) => {
    setCurrentPage(value);

    const result = await trafficLogsServices.fetchLogs({
      ...filter,
      date: getStartOfDayUTCTimestamp(filter.date),
      toDate: getStartOfDayUTCTimestamp(filter.toDate),
      offset: value * pageSize,
      sort: {
        field: order.id,
        direction: order.direction,
      },
    });
    setTrafficLogs(result.data);
  };

  const handleDateChange = (date) => {
    const newFilter = {
      ...filter,
      date,
      sort: {
        field: order.id,
        direction: order.direction,
      },
    };
    setCurrentPage(0);
    setFilter(newFilter);
    search(newFilter);
  };

  const handleToDateChange = (toDate) => {
    const newFilter = {
      ...filter,
      toDate,
      sort: {
        field: order.id,
        direction: order.direction,
      },
    };
    setCurrentPage(0);
    setFilter(newFilter);
    search(newFilter);
  };

  const handleSearch = (e) => {
    const { value: searchText } = e.target;
    const newFilter = {
      ...filter,
      searchText,
      sort: {
        field: order.id,
        direction: order.direction,
      },
    };
    setCurrentPage(0);
    setFilter(newFilter);
    search(newFilter);
  };

  const handleSwitchChange = (e) => {
    const { checked: groupBySubId } = e.target;
    const newFilter = {
      ...filter,
      groupBySubId,
    };
    if (groupBySubId) {
      newFilter.groupKey = '';
    }
    setCurrentPage(0);
    setFilter(newFilter);
    search(newFilter);
  };

  const search = async (filter) => {
    const date = getStartOfDayUTCTimestamp(filter.date);
    const toDate = getStartOfDayUTCTimestamp(filter.toDate);
    const result = await trafficLogsServices.fetchLogs({
      ...filter,
      date,
      toDate,
    });
    setTrafficLogs(result.data);
    const totalPages = parseInt(result.totalRecords / pageSize);
    setTotalPages(totalPages);
  };

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
    if (id === "goodClickCtr" || id === "badClickCtr" || id === "totalCtr") {
      handleSort(id, direction);
    } else {
      search({
        ...filter,
        sort: {
          field: id,
          direction,
        },
      });
    }
  }

  const handleSort = (id, direction) => {
    const result = orderBy(trafficLogs, id, direction);
    setTrafficLogs(result);
    return result;
  };

  const handleAssignSubIdsToGroup = async (e) => {
    if (state.subId.length > 0) {
      const { value } = e.target;
      try {
        setIsSubmitting(true);

        await groupService.addSubIds({
          subIds: state.subId,
          groupKey: value,
        });
        await search(filter);
        setOpen(true);
        setMessage(() => "Subid(s) added successfully");
        setSeverity(() => "success");
      } catch (error) {
        console.error(error);
        setOpen(true);
        setMessage(() => "Error occurred while adding subid(s)");
        setSeverity(() => "error");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleGroupFilterChange = async (e) => {
    const { value: groupKey } = e.target;
    const newFilter = { ...filter, groupKey };
    setFilter(newFilter);
    await search(newFilter);
  };

  const handleChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setState(({ subId }) => ({ subId: [...subId, value] }));
    } else {
      setState(({ subId }) => ({ subId: subId.filter((e) => e !== value) }));
    }
  };

  const handleToastClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper className="w-full rounded-20 px-16  shadow leading-normal">
          <div className="flex flex-col flex-shrink-0 sm:flex-row flex justify-between py-12 ">
            <h2 className="text-xl pb-6 pt-16 pl-12"> Traffics </h2>
            <div className="pt-12">
              <TextField
               sx={{
                maxWidth: 225,
              }}
                id="searchField"
                placeholder="Search..."
                variant="outlined"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="pt-12">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Select a from date"
                  value={filter.date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </LocalizationProvider>
            </div>
            <div className="pt-12">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Select a to date"
                  value={filter.toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="pt-12">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter.groupBySubId}
                      onChange={handleSwitchChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Group By Subid"
                />
              </FormGroup>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="pl-4">
              {filter.groupBySubId && (
                <Groups
                  onChange={handleAssignSubIdsToGroup}
                  name="group"
                  label="Assign to a group"
                  title="Assign to a group"
                  groups={groups}
                />
              )}

              {!filter.groupBySubId && (
                <Groups
                  onChange={handleGroupFilterChange}
                  name="group"
                  label="Select group"
                  title="Group"
                  groups={groups}
                />
              )}
            </div>
        </div>
        <Divider className="py-2 md:w-full" />
        <div className="flex flex-col flex-1 overflow-y-auto min-h-full md:w-full table-responsive relative">
          <Table stickyHeader>
            <LogTableHeader
              order={order}
              onRequestSort={handleRequestSort}
              rowCount={trafficLogs.length}
              groupBySubId={filter.groupBySubId}
            />
            <TableBody>
              {trafficLogs &&
                trafficLogs.map((trafficLog, index) => {
                  return (
                    <TrafficLogsListItem
                      groupBySubId={filter.groupBySubId}
                      trafficLogs={trafficLog}
                      key={index}
                      onChange={handleChange}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {totalPages > 1 && (
          <div className="flex justify-center pt-6">
            <Stack align="right" spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div>
        )}
      </Paper>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleToastClose}
        message={message}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TrafficLogsList;
