import axios from 'axios';
import { baseApiUrl } from '../../config';

class keywordLogServices {
  fetchKeywordLogs = async (filter) => {
    const response = await axios.post(`${baseApiUrl}/keywords`, filter);
    return response.data;
  };
  delete = async (data) => {
    await axios.put(`${baseApiUrl}/keywords`, data);
  };
  deleteAllKeywords = async (data) => {
    await axios.delete(`${baseApiUrl}/keywords`, { data })
  }
}
const instance = new keywordLogServices();

export default instance;
