import moment from "moment";
import "moment-timezone";
//Get TimeStamp of STARTOF day UTC Timezone.
export function getStartOfDayUTCTimestamp(date) {
	date = date || new Date();
	date = moment(date).format("YYYY-MM-DD");
	return moment.utc(date).startOf("day").toDate().getTime();
}

export function simpleFloat(value) {
	return parseFloat(value).toFixed(2);
}

export function utcToLocal(time) {
	const localtz = moment.tz.guess();
	return moment.utc(time).tz(localtz).format("DD MMM YYYY");
}

export function toDate(time) {
	return moment.utc(time).format();
}
