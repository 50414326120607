import axios from 'axios';
import { baseApiUrl } from '../../config';

class groupServices {
  create = async (data) => {
    await axios.post(`${baseApiUrl}/groups`, data);
  };

  fetchGroups = async () => {
    const response = await axios.get(`${baseApiUrl}/groups`);
    return response.data;
  };

  delete = async (key) => {
    await axios.delete(`${baseApiUrl}/groups`, { data :{key} });
  };

  addSubIds = async (subIds) => {
    await axios.post(`${baseApiUrl}/groups/subids`, subIds);
  };

  deleteSubIds = async (key) => {
    await axios.delete(`${baseApiUrl}/groups/subids`, {data:{key}} );
  };
}
const instance = new groupServices();

export default instance;
