import { useState } from "react"
import keywordLogsService from "../../services/keywordLogsService";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import { DialogActions } from '@mui/material';
import {
    Typography,
    
    Button,
    IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

function DeleteConfirmationDialog({ open, onClose, isSubmitting, onDelete }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: 'm-24',
            }}
            fullWidth
            maxWidth="sm"
        >
            <Toolbar className="flex w-full justify-between">
                <Typography
                    variant="h6"
                    color="inherit"
                    className="font-semibold text-18 sm:text-24"
                >
                    Delete
                </Typography>

                <IconButton color="secondary"></IconButton>
            </Toolbar>

            <DialogContent classes={{ root: 'p-24' }}>
                <div className="font-bold">
                    Are you sure to delete this record?
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    id="btnClose"
                    className="btn btn-primary btn-sm"
                    onClick={onClose}
                    loading={isSubmitting}
                >
                    No
                </Button>
                <LoadingButton
                    id="btnDelete"
                    className="btn btn-dark btn-sm ms-5"
                    variant="contained"
                    color="secondary"
                    onClick={onDelete}
                    loading={isSubmitting}
                >
                    Yes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteConfirmationDialog