import axios from 'axios';
import { baseApiUrl } from '../../config';

class importKeywordServices {
  uploadFile = async (formData) => {
    const response = await axios.post(`${baseApiUrl}/keyword/upload`, formData);
    return response.data;
  };

  fetchFile = async (collectionName) => {
    const response = await axios.post(`${baseApiUrl}/keyword/files`, { collectionName });
    return response.data;
  }

  importFile = async (FileCollectionKey) => {
    const response = await axios.post(`${baseApiUrl}/keyword/import`, {FileCollectionKey});
    return response.data;
  };
  
}
const instance = new importKeywordServices();

export default instance;
