import List from '@mui/material/List';
import CollectionListItem from './CollectionListItem';

const CollectionList = ({ collections,onDeleteOpen,onEdit }) => {
  return (
    <List>
      {collections.map((collection) => (
        <CollectionListItem collection={collection} onDeleteOpen={onDeleteOpen} onEdit={onEdit}/>
      ))}
    </List>
  );
};

export default CollectionList;
