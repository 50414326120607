import { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import { lighten } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const dateColumn = {
  id: "date",
  align: "left",
  disablePadding: false,
  label: "Date",
  sort: true,
};

const checkBoxColumn = {
  id: "check",
  align: "left",
  disablePadding: false,
  label: "",
};

const rows = [
  {
    id: "subid",
    align: "left",
    disablePadding: false,
    label: "Sub id",
    sort: true,
  },
  {
    id: "group",
    align: "left",
    disablePadding: false,
    label: "Groups",
  },
  {
    id: "goodClicks",
    align: "right",
    disablePadding: false,
    label: "Good Clicks",
    sort: true,
  },
  {
    id: "badClicks",
    align: "right",
    disablePadding: false,
    label: "Bad Clicks",
    sort: true,
  },
  {
    id: "totalTraffic",
    align: "right",
    disablePadding: false,
    label: "Total Traffic",
    sort: true,
  },
  {
    id: "goodClickCtr",
    align: "right",
    disablePadding: false,
    label: "Good Click CTR",
    sort: true,
  },
  {
    id: "badClickCtr",
    align: "right",
    disablePadding: false,
    label: "Bad Click CTR",
    sort: true,
  },
  {
    id: "totalCtr",
    align: "right",
    disablePadding: false,
    label: "Total Click CTR",
    sort: true,
  },
];

function LogTableHeader(props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props.groupBySubId) {
      setColumns([checkBoxColumn, ...rows]);
    } else {
      setColumns([dateColumn, ...rows]);
    }
  }, [props.groupBySubId]);

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((row) => {
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "normal"}
              sortDirection={
                props.order.id === row.id ? props.order.direction : false
              }
            >
              {row.sort && (
                <Tooltip
                  title="Sort"
                  placement={
                    row.align === "right" ? "bottom-end" : "bottom-start"
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={props.order.id === row.id}
                    direction={props.order.direction}
                    onClick={createSortHandler(row.id)}
                    className="font-semibold"
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              )}
              {!row.sort && (
                <Typography className="font-semibold"> {row.label} </Typography>
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default LogTableHeader;
