import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import {
  TableBody,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Stack,
  Pagination,
  Button,
  IconButton,
  Paper,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeywordLogsListItem from './KeywordLogsListItem.';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import { DialogActions } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import reject from 'lodash/reject';
import keywordLogsService from '../../services/keywordLogsService';
import settingService from '../../services/settingService';
import ClearAllKeywordDialog from './ClearAllKeywordsDialog';
import { Link } from 'react-router-dom';

const pageSize = 10;
const initialFilter = {
  keywordsNotEqual: false,
  collectionName: 'Default',
};

function KeywordLogsList() {
  const [hitsLog, setHitsLog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteAllKeywordsOpen, setDeleteAllKeywordsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [filter, setFilter] = useState(initialFilter);
  const [toastOpen, setToastOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await keywordLogsService.fetchKeywordLogs(filter);
      setHitsLog(result);
    };
    fetch();
  }, [filter]);

  useEffect(() => {
    const fetch = async () => {
      const collections = await settingService.listCollection();
      setCollections(collections);
    };
    fetch();
  }, []);

  const lastIndexValue = pageSize * currentPage;
  const firstIndexValue = lastIndexValue - pageSize;
  const keywordsLogs = hitsLog.slice(firstIndexValue, lastIndexValue);
  const pageValues = Math.ceil(hitsLog.length / pageSize);

  const handlePageChange = async (e, value) => {
    setCurrentPage(value);
  };

  const handleSwitchChange = async (e) => {
    const { checked: keywordsNotEqual } = e.target;
    const newFilter = {
      ...filter,
      keywordsNotEqual,
    };
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const handleDeletePopUpOpen = (keyword) => {
    setSelectedKey(keyword);
    setOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setOpen(false);
  };

  const handleDeleteAllKeywordsOpen = () => {
    setDeleteAllKeywordsOpen(true)
  }

  const handleDeleteAllKeywordsClose = () => {
    setDeleteAllKeywordsOpen(false)
  }

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);
      await keywordLogsService.delete({
        keyword: selectedKey,
        collectionName: filter.collectionName,
      });
      handleDeletePopUpClose();
      handleToastSuccessMessage()
      const results = await keywordLogsService.fetchKeywordLogs({ ...filter });
      setHitsLog(results);
    } catch (err) {
      handleToastErrorMessage()
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleToastClose = () => {
    setToastOpen(false);
  };

  const handleToastSuccessMessage = () => {
    setToastOpen(true);
    setMessage(() => 'Deleted successfully');
    setSeverity(() => 'success');
  }

  const handleToastErrorMessage = () => {
    setToastOpen(true);
    setMessage(() => 'Error occurred while deleting');
    setSeverity(() => 'error');
  }

  const handleCollectionChange = (e) => {
    const { value: collectionName } = e.target;
    setFilter({ ...filter, collectionName });
  };

  const handleSetKeywords = (keywords) => {
    setHitsLog(keywords)
  }

  return (
    <>
      <Paper className="w-full rounded-20 px-16 py-10  shadow leading-normal ">
        <div className="flex justify-between w-full">
          <h1 className="text-xl pb-6 pt-16 pl-14"> Keywords </h1>
          <div className="flex justify-between">
            <div className="mr-24 pt-10">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Collection</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={filter.collectionName}
                  label="Collection"
                  defaultValue=""
                  onChange={handleCollectionChange}
                >
                  <MenuItem value="Default">Default</MenuItem>
                  {reject(collections, { is_default: true }).map(
                    (collection) => (
                      <MenuItem value={collection.name} key={collection.name}>
                        {collection.displayName}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="pt-12">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter.keywordsNotEqual}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="All"
                />
              </FormGroup>
            </div>
            <div className='pt-12'>
              <Button variant="contained" onClick={handleDeleteAllKeywordsOpen}>
                Clear All Keywords
              </Button>
            </div>
            <div className='pt-12 pl-4'>
              <Link to="/upload-keywords">
                <Button variant="contained" >
                  Upload keywords
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <Divider className="py-2" />
        <div className="w-full flex flex-col min-h-full md:w-full table-responsive overflow-x-auto relative">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-lg font-semibold">
                  <Typography
                    color="textSecondary"
                    className="text-lg font-semibold"
                  >
                    Keyword
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textSecondary" className="font-semibold">
                    Hits
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textSecondary" className="font-semibold">
                    Matched Hits
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" className="font-semibold">
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywordsLogs.map((log, index) => (
                <KeywordLogsListItem
                  log={log}
                  key={index}
                  onDeletePopUp={handleDeletePopUpOpen}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        {pageValues > 1 && (
          <div className="flex justify-center pt-6">
            <Stack align="right" spacing={2}>
              <Pagination
                count={pageValues}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div>
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={handleDeletePopUpClose}
        classes={{
          paper: 'm-24',
        }}
        fullWidth
        maxWidth="sm"
      >
        <Toolbar className="flex w-full justify-between">
          <Typography
            variant="h6"
            color="inherit"
            className="font-semibold text-18 sm:text-24"
          >
            Delete
          </Typography>

          <IconButton color="secondary"></IconButton>
        </Toolbar>

        <DialogContent classes={{ root: 'p-24' }}>
          <div className="font-bold">
            Are you sure to delete this record?
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnClose"
            className="btn btn-primary btn-sm"
            onClick={handleDeletePopUpClose}
            loading={isSubmitting}
          >
            No
          </Button>
          <LoadingButton
            id="btnDelete"
            className="btn btn-dark btn-sm ms-5"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            loading={isSubmitting}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {deleteAllKeywordsOpen &&
        <ClearAllKeywordDialog
          open={deleteAllKeywordsOpen}
          onClose={handleDeleteAllKeywordsClose}
          filter={filter}
          fetchKeywords={handleSetKeywords}
          successToastMessage={handleToastSuccessMessage}
          errorToastMessage={handleToastErrorMessage}
        />}
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleToastClose}
        message={message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default KeywordLogsList;
