export const statusList = [
    {
      id: "Processing",
      name: "Processing",
      color: "bg-blue text-white",
    },
    {
      id: "Success",
      name: "Success",
      color: "bg-green text-white",
    },
    {
      id: "Error",
      name: "Error",
      color: "bg-pink text-white",
    },
    {
      id: "Rejected",
      name: "Rejected",
      color: "bg-orange text-black",
    },
  ];