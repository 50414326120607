import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import {
  Card,
  TableBody,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Button,
  DialogActions,
  Toolbar,
  Snackbar,
  Alert,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import groupService from '../../services/groupService';
import GroupsListItem from './GroupsListItems';
import Group from './Group';

const initialState = {
  open:false,
  message:'',
  isSubmitting: false,
}

function GroupsList() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOpenWIthSubid, setDeleteOpenWithSubid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedData, setSelectedData] = useState({ groupName: '' });
  const [groups, setGroups] = useState('');
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const fetch = async () => {
      const result = await groupService.fetchGroups();
      setGroups(result);
    };
    fetch();
  }, []);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = async () => {
    setOpen(false);
    const result = await groupService.fetchGroups();
    setGroups(result);
  };

  const handleDeletePopUpOpen = (group) => {
    setSelectedId(group);
    setDeleteOpen(true);
  };

  const handleDeletePopUpOpenWithSubid = (group) => {
    setSelectedId(group);
    setDeleteOpenWithSubid(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteCloseWithSubid = () => {
    setDeleteOpenWithSubid(false);
  };

  const handleToastClose = () => {
    setState(initialState) 
  };

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);
      await groupService.delete(selectedId);
      const result = await groupService.fetchGroups();
      setGroups(result);
      setState({ ...state, isSubmitting: true, open:false, message: 'Deleted successfully'});
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      handleDeleteClose();
    }
  };

  const handleDeleteSubidInGroup = async () => {
    try {
      setIsSubmitting(true);
      await groupService.deleteSubIds(selectedId);
      const result = await groupService.fetchGroups();
      setGroups(result);
      setState({ ...state, isSubmitting: true, open:false, message: 'Deleted all subids in this group successfully'});
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      handleDeleteCloseWithSubid();
    }
  };

  const handleEdit = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  return (
    <>
    <Card className="w-full rounded-20 px-16 py-10  shadow leading-normal">
        <div className="flex justify-between w-full">
          <h1 className="text-xl pb-6 pt-8"> Groups </h1>
          <div className='pt-4'>
            <Button variant="contained" onClick={handleDialogOpen}>
              Add group
            </Button>
          </div>
        </div>

        <Divider className="py-2" />
        <div className="w-full flex flex-col min-h-full">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-lg font-semibold">
                  <Typography
                    color="textSecondary"
                    className="text-lg font-semibold"
                  >
                    Groups
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" className="font-semibold">
                    Edit
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" className="font-semibold">
                    Remove all subids in this group
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" className="font-semibold">
                    Delete group
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups &&
                groups.map((group) => (
                  <GroupsListItem
                    groups={group}
                    onDelete={handleDeletePopUpOpen}
                    onDeleteWithSubId={handleDeletePopUpOpenWithSubid}
                    onEdit={handleEdit}
                  />
                ))}
            </TableBody>
          </Table>
        </div>
      </Card>
      <Snackbar
        open={state.message.length > 0}
        autoHideDuration={5000}
        onClose={handleToastClose}
        message={state.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
      {open && (
        <Group
          open={open}
          onClose={handleDialogClose}
          selectedData={selectedData}
        />
      )}
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        classes={{
          paper: 'm-24',
        }}
        fullWidth
        maxWidth="sm"
      >
        <Toolbar className="flex w-full justify-between">
          <Typography
            variant="h6"
            color="inherit"
            className="font-semibold text-18 sm:text-24"
          >
            Delete
          </Typography>
        </Toolbar>

        <DialogContent classes={{ root: 'p-24' }}>
          <div className="text-16 font-bold">
            Are you sure to delete this record?
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnClose"
            className="btn btn-primary btn-sm"
            onClick={handleDeleteClose}
            loading={isSubmitting}
          >
            No
          </Button>
          <Button
            id="btnDelete"
            className="btn btn-dark btn-sm ms-5"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            loading={isSubmitting}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteOpenWIthSubid}
        onClose={handleDeleteCloseWithSubid}
        classes={{
          paper: 'm-24',
        }}
        fullWidth
        maxWidth="sm"
      >
        <Toolbar className="flex w-full justify-between">
          <Typography
            variant="h6"
            color="inherit"
            className="font-semibold text-18 sm:text-24"
          >
            Delete
          </Typography>
        </Toolbar>

        <DialogContent classes={{ root: 'p-24' }}>
          <div className="font-bold">
            Are you sure to remove all subids in this group?
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnClose"
            className="btn btn-primary btn-sm"
            onClick={handleDeleteCloseWithSubid}
            loading={isSubmitting}
          >
            No
          </Button>
          <Button
            id="btnDelete"
            className="btn btn-dark btn-sm ms-5"
            variant="contained"
            color="secondary"
            onClick={handleDeleteSubidInGroup}
            loading={isSubmitting}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default GroupsList;
