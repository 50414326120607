import React from "react";

function SearchListItem({ result, goodClick, badClick, index }) {
  const isEven = index % 2;
  return (
    <div>
      <div className="text-sm text-gray-600"></div>
      <div className="pt-2">
        <div className="container flex-col basis-1/2">
          <div className="basis-1/2">
            <div>
              <div className="text-blue-600pt-3">
                <a
                  className="text-blue-600"
                  href={result.siteHost}
                  onClick={goodClick}
                >
                  {result.siteHost}
                </a>
              </div>
              <div className="text-xl font-normal text-blue-600">
                <a href={result.clickUrl} onClick={goodClick}>
                  {result.title}
                </a>
              </div>
              <p className="text-sm" onClick={goodClick}>
                {result.description}
              </p>
              <a className="text-white" onClick={badClick}>
                {isEven ? "hello" : <br />}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchListItem;
