import axios from 'axios';
import { baseApiUrl } from '../../config';

class settingServices {
  fetchGetKeywords = async (filter) => {
    const response = await axios.post(`${baseApiUrl}/settings`, filter);
    return response.data;
  };

  fetchKeywords = async (key) => {
    const response = await axios.get(`${baseApiUrl}/settings?key=${key}`);
    return response.data;
  };

  create = async (data) => {
    await axios.post(`${baseApiUrl}/setting`, data);
  };

  listCollection = async () => {
    const response = await axios.get(`${baseApiUrl}/settings/collections`);
    return response.data;
  };


  listNames = async () => {
    const response = await axios.get(`${baseApiUrl}/settings/names`);
    return response.data;
  };

  createCollection = async (collection) => {
    const response = await axios.post(
      `${baseApiUrl}/settings/collections`,
      collection,
    );
    return response.data;
  };

  deleteSettingCollection = async (key) => {
    await axios.put(`${baseApiUrl}/settings/collections`, key);
  }

  deleteRedirectUrlSetting = async (data) => {
    await axios.delete(`${baseApiUrl}/settings/redirectUrl`, { data })
  }
}
const instance = new settingServices();

export default instance;
