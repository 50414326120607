import React from "react";
import { OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchField() {
  return (
    <div className="w-full flex justify-left flex-row pb-6">
      <OutlinedInput
        sx={{ width: "75%", borderRadius: "30px", height: "54px" }}
        id="search"
        placeholder="search..."
        endAdornment={
          <InputAdornment position="end">
            <IconButton color="primary">
              <SearchIcon edge="end"></SearchIcon>
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
}
export default SearchField;
